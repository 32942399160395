import React from 'react';
import {Grid, Container, Typography} from '@mui/material'; 
import Card from '../card';
import Charles from '../img/Charles.svg';
import Shane from '../img/shane.svg';
import Steven from '../img/Steven.svg';
import Audrey from '../img/Audrey.svg';
import '../scss/teamArea.scss';





function TeamArea(){
    return(
        <div id="team" className="team-div">
            <Container className='team-con'>
                
                    <Grid container align="center" spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Typography 
                            variant='h3'
                            sx={{
                                color:'white'
                            }}>
                            Our Team
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card 
                            image={Charles}
                            background="logo-background"
                            effect= "avatar-pic"
                            title="Charles Lupi"
                            description="
                            Owner
                            "
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card 
                            image={Shane}
                            background="logo-background"
                            effect="avatar-pic"
                            title="Shane Lupi"
                            description="
                            Owner/Operations Manager
                            "
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card 
                            image={Steven}
                            background="logo-background"
                            effect="avatar-pic"
                            title="Steven Flier"
                            description="
                            Project Manager
                            "
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card 
                            image={Audrey}
                            background="logo-background"
                            effect="avatar-pic"
                            title="Audrey Phillips"
                            description="
                            Office Manager
                            "
                            />
                        </Grid>
                    </Grid>
            </Container>
        
        </div>
    )
}

export default TeamArea;