import React from 'react';
import {
  
    Paper,
    Box,  
    Typography,
 } from '@mui/material';
import './scss/cards.scss';


function CallingCard(props) {
    return(
        <React.Fragment>
           
                        <Paper 
                        elevation={10}
                        sx={{
                            bgcolor: 'primary.main',
                            width: '15rem',
                            textAlign: 'center',
                            alignItems: 'center',
                            border: '.5px solid black',
                            mt: 5,
                            pt: 1,
                            borderRadius: 3,
                            transition: 'all .7s ease-in-out',
                            '&: hover': {
                                boxShadow: '2px 3px 20px black',
                                transform: 'scale(1.023)',
                            }
                            }}>
                                <Box 
                                component='div'
                                sx={{
                                    width: '100%',
                                    height: '150px',
                                    borderBottom: '.2px solid gray',
                                    
                                }}>
                                <div className={props.background}>
                                <img 
                                src={props.image} 
                                alt="Lupi Construction"
                                 className={props.effect}>
                                </img>
                                </div>
                            </Box>
                            <Box 
                            component='div'
                            sx={{
                                borderBotton: '.2px solid black'
                            }}
                            >
                                <Typography
                                component='div'
                                sx={{
                                   
                                    fontWeight: 600,
                                    bgcolor: 'secondary.main'
                                }}
                                variant='h5'>{props.title}
                                </Typography>
                            </Box>
                            <Box
                            component='div'
                            sx={{
                                borderTop: '.2px solid black',
                            }}
                            >
                                <Typography
                                component='div'
                                variant="subtitle1"
                               
                                sx={{
                                    p: 1,
                                    color: 'white',
                                }}
                                >
                                {props.description}
                                </Typography>
                            </Box>
                        </Paper>
        </React.Fragment>
       
    )
}

export default CallingCard;