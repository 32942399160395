import React, {useState, useRef} from 'react';
import {
    Grid,
    FormControl,
    Typography, 
    TextField,
    Button,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 

function ContactForm() {

    const form = useRef()
    const [firstName, setFirstName] =useState()
    const [lastName, setLastName] =useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [message, setMessage] = useState()

    const successMessage = () => {
        toast.success(`Thanks ${firstName} we will reach out soon!`, {
            position: toast.POSITION.TOP_CENTER
          });
    
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        sendEmail();
        successMessage();

    }

    


    
    
    
    const sendEmail =() => {
        
        emailjs.sendForm('service_g7bex1e', 'template_mes89ac', form.current, '0OMSi7LxqbfghydME')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    }
       
        
    
    return(
        <form ref={form} onSubmit={handleSubmit} autoComplete={false}>
            <ToastContainer/>
            <Grid container 
            rowSpacing={1}
            columnSpacing={1}
            alignItems="center"
            sx={{
                m: 4,
                width: '100%',
            }}
            >
            <Grid item xs={12} md={12}>
            <Typography
            variant='h5'
            align="center"
            >
            Contact us Here! 
            </Typography>
            
            </Grid>
                <Grid item xs={12} md={6}>
                <FormControl>
                  
                    <TextField
                    fullWidth={true}
                    required
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    type='text'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    variant='filled'
                    />

                </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                <FormControl>
                    
                    <TextField
                    fullWidth={true}
                    required
                    name="lastName"
                    type='text'
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    variant='filled'
                    />
                </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                <FormControl>
                    
                    <TextField
                    fullWidth={true}
                    required
                    id="email"
                    name="email"
                    type='email'
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="filled"
                    />
                </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                <FormControl>
                    
                    
                    <TextField
                    fullWidth={true}
                    label="Phone Number"
                    id="phone"
                    name="phone"
                    type='text'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    variant='filled'
                    
                    />
                </FormControl>
                </Grid>
                <Grid 
                item xs={12} md={12}
                >
                <TextField
                  fullWidth={true}
                    variant='filled'
                    required
                    id="outlined-textarea"
                    type='textarea'
                    name="message"
                    label="message"
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button 
                    variant="contained" 
                    type="submit"
                    endIcon={<SendIcon />}>
                    Submit
                    </Button>
            
                   
                   
                    
                
                </Grid>
            
            
            
            </Grid>
        
        </form>
    )
}

export default ContactForm;