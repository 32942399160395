import React from "react";

import {
    Grid,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Container,
    
} from '@mui/material';
import { 
    FaRegBuilding,
    FaPhone,
} from "react-icons/fa";
import '../scss/footer.scss';
import ContactForm from '../form';



function Footer() {

   

    return(
        <React.Fragment>    
            <div id="contact" className="footer-div">
                <Container>
                    <Grid 
                    container
                    alignItems='center'
                    >
                    <Grid item xs={12} md={12}>
                        <Typography
                        variant="h3"
                        align="center"
                        >
                        Commerical Concrete Done Right!
                        </Typography>
                    
                    </Grid>
                    <Grid 
                    item xs={12} md={6}
                    sx={{
                        marginTop: '10px',
                    }}
                    
                    >
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <FaPhone/>
                                </ListItemIcon>
                                <ListItemText primary="580-355-3599"/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <FaRegBuilding/>
                                </ListItemIcon>
                                <ListItemText primary="17 SW H AVE Lawton, OK 73501"/>
                            </ListItem>
                            <ListItem>
                                <iframe 
                                title="Lupi Construction"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.17119466287!2d-98.39041818473653!3d34.59983229612138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87ad19f3390aba5f%3A0x78c150f1ada3c668!2sLupi%20Construction!5e0!3m2!1sen!2sus!4v1652049327010!5m2!1sen!2sus" 
                                width="400" 
                                height="300" 
                                className="lupi-location"
                                allowFullScreen="" 
                                loading="lazy" 
                                referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </ListItem>
                        </List>
                    </Grid>
                        <Grid item xs={12} md={4}>
                        <ContactForm/>
                       

                        </Grid>
                        <Grid 
                        item xs={12} 
                        md={12}
                        sx={{
                            borderTop: '1px solid black'
                        }}
                        
                        >
                        <Typography
                        varaint="caption"
                        align="center">
                        Proudly Designed by Decrypted-Tech LLC, Lawton OK, All rights reserved.
                        Call us at 580-382-2112
                        
                        </Typography>

                        </Grid>
                    </Grid>
                </Container>
            </div>
        </React.Fragment>
    )

}

export default Footer;
