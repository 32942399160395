import React from 'react';
import {Container } from 'react-bootstrap';
import {
   
    Grid,
    Typography,
    Box,
} from '@mui/material';
import Pics from '../servicesImage';
import Team from '../img/team.JPG';
import '../scss/storyArea.scss';


function StoryArea() {
    return(
        <div id="company" className="story-div">
            <Container className="story-con">
            
                    <Grid 
                    container 
                    spacing={3}
                    sx={{
                        p: 2,
                    }}
                    >
                    
                        <Grid item xs={12} md={12}>

                        <Typography 
                            component="div"
                            variant='h4'
                            align='center'
                           
                           
                            sx={{
                                mb: 3,
                            }}
                            >
                            Our Company
                            </Typography>
                           

                        </Grid>
                       <Grid item xs={12} md={4}>
                             <Typography
                             variant='h5'
                             align="center"
                             sx={{
                                mb: 2,
                             }}>
                             About Us
                             </Typography>  
                            <Typography
                            variant ='body1'
                            align='justify'
                            >
                            Lupi Construction is a privately owned family business. 
                            In 1985, in its humble beginnings, Charles would spend his days
                            fighting the intense Oklahoma sun working well into the night with a 
                            flashlight in his mouth as his only source of light to ensure the job was done and done well. 
                            His hard work and dedication paid off as those projects 
                            still stand showing testament to the quality of Lupi Construction’s 
                            work. His son Shane, at a young age, spent his evenings, weekends, and school breaks learning 
                            all there is about concrete while helping his father complete many projects. Shane admired his father's 
                            hardwork and dedication to quality. Shane went on to obtain his degree in Construction Managment and came back to work 
                            with Charles at Lupi Construction. The father son duo has a unique passion for concrete and the local community. The Lupi name, is well
                            known in Lawton, Oklahoma and surrounding areas.
                            </Typography>
                            <br/>
                            <Typography
                            variant ='body1'
                            align='justify'
                            sx={{
                                mb: 3,

                            }}
                            >
                            Together Charles and Shane work side by side to make 
                            Lupi Construction what it is today. We believe that 
                            relationships rest at the foundation of our success. We value 
                            the relationship with our clients. We strive to complete each 
                            project on-time and exceed our client’s expectations. The key 
                            behind what Lupi Construction does is the mentality that we 
                            can always get better each day.
                            </Typography>
                       </Grid>
                       <Grid item xs={12} md={4}>
                       <Typography
                       variant="h5"
                       align="center"
                       
                       sx={{
                           mb: 2,
                       }}
                       >
                        Our Passion
                       </Typography>
                       <Box
                       component='img'
                       src={Team}
                       sx={{
                           width: 400,
                           mt: 2,
                           borderRadius: 2,
                           boxShadow: '1px 2px 15px gray',
                           mb: 1
                       }}/>
                       <Typography
                       variant ='body2'
                       align='center'
                       sx={{
                           mb: 3,
                       }}
                       >
                       Charles and Shane in are pictured in the middle woking
                           on the dowtown Lawton Branch for Liberty Bank.
                       </Typography>
                       <iframe
                       className="lupi-work-vid"
                        src="https://drive.google.com/file/d/1k41kdL-PeGjO1QDZ8FTuZNyj76PgMSSs/preview" 
                        frameBorder="0"
                        title="Lupi at work"></iframe>
                    
                       
                       </Grid>
                      
                       <Grid item xs={12} md={4}
                       sx={{
                           mt: 7,
                       }}
                       >
                            <Pics/>   
                       </Grid>
                    </Grid>
          
            </Container>
        </div>
    )
}

export default StoryArea;