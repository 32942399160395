import React from 'react';
import {
   
    ImageList,
    ImageListItem,
 
} from '@mui/material';
import {ImageData} from './constants';


function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

function PictureSlider() {
    return(
        <React.Fragment>
        <ImageList
        sx={{width: 400,
           height: 565,
      
           bgcolor: 'white',
           borderRadius: '5px',
           border: '.2rem solid white',
           boxShadow: '4px 6px 12px black',
           
           }}
        variant="quilted"
       cols={8}
        rowHeight={100}
        >
        {ImageData.map((item) => (
      
            <ImageListItem 
            key={item.img} 
            cols={item.cols || 2} 
            rows={item.rows || 1}
            sx={{
            
            }}
            >
              <img
                {...srcset(item.img, 121, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
       
            </ImageListItem>
          ))}
        </ImageList>
        </React.Fragment>
    )
} 

export default PictureSlider;

