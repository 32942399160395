import React from 'react';
import {
    Grid,
} from '@mui/material';
import { Container, Row } from 'react-bootstrap';
import ServiceList from '../serviceList'
import '../scss/servicearea.scss';


function ServiceContainer() {
    return(
      <React.Fragment>
       
        <div className="service-div">
        <Row id="services" className="service-title-row">
       <h1 className="service-title-h1"> Lupi Construction Services</h1>
       <h5>We leverage 40 years of exprience and technological advances 
       to bring our vast array of service options to every single project.
       </h5>
       </Row>
       <Container className="service-container">
       
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <ServiceList name="Plan Reading"/>
          </Grid>
          <Grid item xs={12} md={4}>
            <ServiceList name="Hand Excavation"/>
          </Grid>
          <Grid item xs={12} md={4}>
            <ServiceList name="Machine Excavation"/>
          </Grid>
          <Grid item xs={12} md={4}>
           <ServiceList name="Foundation"/>
          </Grid>
          <Grid item xs={12} md={4}>
           <ServiceList name="Layout from Construction Document"/>
          </Grid>
          <Grid item xs={12} md={4}>
           <ServiceList name="Flat Work"/>
          </Grid>
          <Grid item xs={12} md={4}>
           <ServiceList name="Site Work"/>
          </Grid>
          <Grid item xs={12} md={4}>
           <ServiceList name="Architectual"/>
          </Grid>
          <Grid item xs={12} md={4}>
           <ServiceList name="Crew Mangement"/>
          </Grid>
          <Grid item xs={12} md={4}>
           <ServiceList name="Concrete Placement"/>
          </Grid>
          <Grid item xs={12} md={4}>
           <ServiceList name="Concrete Finishing"/>
          </Grid>
          <Grid item xs={12} md={4}>
           <ServiceList name="Planning and Scheduling"/>
          </Grid>
          <Grid item xs={12} md={4}>
           <ServiceList name="Daily Logging"/>
          </Grid>
          <Grid item xs={12} md={4}>
           <ServiceList name="Rebar Reinforcement"/>
          </Grid>
          <Grid item xs={12} md={4}>
           <ServiceList name="Budgeting"/>
          </Grid>
        </Grid>
        </Container>
        </div>
        </React.Fragment>
    )

}

export default ServiceContainer;