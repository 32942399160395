import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
} from '@mui/material'
import { FaHardHat } from 'react-icons/fa';



function ServiceList(props) {
    return(
        <List 
        sx={{ 
            boxSizing:'border-box',
            boxShadow: '2px 1px 5px black',
            bgcolor: 'primary.main',
            color: 'white',
            transition: 'all .2s ease-in-out',
            borderRadius: '5px',
            border: '.5px solid black',
            width: 'calc( 100% - 1em )',
            '&:hover': {
                boxShadow: '2px 3px 15px black',
                color: 'secondary.main',
            }

            }}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <FaHardHat className="service-icon" />
                    </Avatar>
                </ListItemAvatar>
                     <ListItemText primary={props.name}/>
            </ListItem>    
        </List>
    )
}

export default ServiceList; 