import React from 'react';
import {Container, Navbar, Nav} from 'react-bootstrap';
import '../scss/navbar.scss';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import Logo from '../img/LupiLogov2.png';
import { Link, animateScroll as scroll } from 'react-scroll';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';



function LupiNav() {



  return(
    <Navbar id="home" expand="lg" sticky="top" className="navbar-main">
    <Container>
    <Navbar.Brand onClick={scroll.scrollToTop}>
        <img
          alt="lupi Construction"
          src={Logo}
          width="100"
          height="100"
          className='d-inline-block align-top logo-navbar'/>
          
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Link className="nav-link" activeClass="active" to="services" spy={true} offset={-80} duration={500}
          href="#services">Services</Link>
          <Link className="nav-link" activeClass="active" to="company" spy={true} offset={-80} duration={500}
          href="#services">Company</Link>
          <Link className="nav-link" activeClass="active" to="team" spy={true} offset={-120} duration={500}
          href="#services">Team</Link>
          <Link className="nav-link" activeClass="active" to="contact" spy={true} offset={-80} duration={500}
          href="#services">Contact</Link>
        </Nav>
        <Nav className='justify-contet-end'>
          <Nav.Link 
          target="_blank"
           href="https://linkedin.com"
           className="icon"><FaLinkedin/></Nav.Link>
          <Nav.Link target="_blank" href="https://facebook.com"className='icon'><FaFacebook/></Nav.Link>
          <Nav.Link target="_blank" href="https://instagram.com"className='icon'><FaInstagram/></Nav.Link>
      </Nav>
      </Navbar.Collapse>
      
      
      </Container>
    
    </Navbar>
  )
}

export default LupiNav;