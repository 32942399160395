import React from "react";
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/banner.scss';
import Vbanner from '../img/LupiVBanner2.mp4';
import { Typography, styled, Grow } from "@mui/material";



function CarouselSlider() {

  const Root = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: '.8em',
    }
    
  }));


    return(
        <React.Fragment>
        <header>
        <div className="overlay"></div>

        
        <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop"
          src={Vbanner}/>
       
      
        
        <Container className="h-100">
          <div className="d-flex h-100 text-center align-items-center">
            <div className="w-100 text-white">
            <Root>
            <Grow 
            in={true}
            style={{transformOrigin:'0 0 0 0'}}
            timeout={{enter: 8000}}

            >
            <Typography
            variant="h1">
             Lupi Construction 
            </Typography>
            </Grow>
            <Grow 
            in={true}
            style={{transformOrigin: '0 0 0'}}
            timeout={{enter:10000}}
            delay={4000}>
            <Typography
            variant="p">
            Commerical Concrete Construction for Oklahoma
            </Typography>
            </Grow>
            </Root>
            

              
            </div>
          </div>
        </Container>
      </header>
        </React.Fragment>

    )
}

export default CarouselSlider;

