import Service1 from './img/service1.jpg';
import Service2 from './img/service2.png';
import Service3 from './img/service3.jpg';
import Service4 from './img/service4.jpg';
import Service5 from './img/service5.jpg';
import Service6 from './img/service6.jpg';
import Service7 from './img/service7.jpg';
import Service8 from './img/service9.jpg';
import Service9 from './img/stairs.jpg';
import Service10 from './img/service10.png';
import Service11 from './img/service11.jpg';
import Service12 from './img/service12.jpg';
import Service13 from './img/service13.jpg';
import Service14 from './img/service14.jpg';



export const ImageData =[
    {
        img: Service14,
        title: 'Lupi at work',
        rows: 3,
        cols: 6,
    },
    {
        img: Service2,
        title: 'Lupi at work',
        rows: 2,
        cols: 2,
    },
    {
        img: Service6,
        title: 'Lupi at work',
        cols: 2,
    },
    {
        img: Service4,
        title: 'Lupi at work',
        cols: 2,
        rows: 1,
    },
    {
        img: Service5,
        title: 'Lupi at work',
    },
    {
        img: Service3,
        title: 'Lupi at work',
        rows: 2,
        cols: 4,
    },
    {
        img: Service7,
        title: 'Lupi at work',
        
    },
    {
        img: Service8,
        title: 'Lupi at work',
        
    },
    {
        img: Service12,
        title: 'Lupi at work',
        cols: 2,
        
    },
    {
        img: Service10,
        title: 'Lupi at work',
        cols: 6,
        rows: 1,
        
    },
    {
        img: Service11,
        title: 'Lupi at work',
        cols: 6,
        
    },
    {
        img: Service9,
        title: 'Lupi at work',
        cols: 2,
        
    },
    {
        img: Service13,
        title: 'Lupi at work',
        cols: 2,
        rows: 2,
        
    },
    {
        img: Service1,
        title: 'Lupi at work',
        cols: 6,
        rows: 2,
        
    },
]