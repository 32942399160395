import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AttentionArea from'./Components/Containers/attentionArea';
import NavHome from './Components/Containers/navbar';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import BannerSlider from './Components/Containers/carobanner';
import ServiceArea from './Components/Containers/serviceArea';
import Team from './Components/Containers/company'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css'; 
import '@fontsource/roboto/700.css';
import Company from './Components/Containers/storyArea';
import Foot from './Components/Containers/footer';
import {Element} from 'react-scroll';




const theme = createTheme({

  typography: {
    h1: {
      fontSize: '3.5rem'
    }
  },

  palette: {
    primary: {
      main: '#424242',
    },
    secondary: {
      main: '#ff9718',
    },
  },

});



function App() {
  return (
  
    <ThemeProvider theme={theme}>

  <NavHome/>
  <BannerSlider/>
  <AttentionArea/>
  <Element name='services' className='element'>
     <ServiceArea />
  </Element>
  <Element name="company" className='element'>
    <Company/>
  </Element>
 <Element name="team" className='element'>
  <Team/>
 </Element>
  <Element name="contact" className='elemnt'>
  <Foot/>
  </Element>

  </ThemeProvider>
    
     
  )
}

export default App;
