import React from 'react';
import { Grid, Container } from '@mui/material';

import CardItem from '../card';
import '../scss/att.scss';
import Logo from '../img/LupiLogov2.png';



function AttentionArea() {
    return(
        <React.Fragment>
        <div className="card-att-div">
        <div className="att-waves">
            <Container className="att-con">
            <Grid 
            container 
            spacing={2}
            align='center'>
                <Grid item xs={12} md={3}>
                    <CardItem 
                    image={Logo}
                    effect="logo-att"
                    title="Technology Enabled"
                    description="
                    We pride ourselves by leveraging the latest
                    technology in our earthwork processes. We use
                    GPS enabled robotic grading
                    equipment to ensure the most accurate results.
                    "/>
                </Grid>
                <Grid item xs={12} md={3}>
                    <CardItem 
                    image={Logo}
                    effect="logo-att"
                    title="Experience"
                    description="
                    Lupi Construction has more than 30 years 
                    of experience in the commercial, industrial, 
                    and municipal concrete construction market within 
                    the state of Oklahoma. We know concrete!
                    "/>
                </Grid>
                <Grid item xs={12} md={3}>
                    <CardItem 
                    image={Logo}
                    effect="logo-att"
                    title="Quality"
                    description="
                    Our attention to detail can be seen from our amazing in-house
                    planning process all the way to delivering a finished 
                    product. We Strive for excellence in everything we do.
                    "/>
                </Grid>
                <Grid item xs={12} md={3}>
                    <CardItem
                    image={Logo} 
                    title="Efficient"
                    effect="logo-att"
                    description="
                    A turn-key earthwork and concrete company which 
                    allows us to manage the schedule with efficiency. 
                    Enabling our clients to get up and running as quickly 
                    as possible.
                    "/>
                </Grid>
            </Grid>
            </Container>
            </div>
            </div>
            
        </React.Fragment>
    )
}
export default AttentionArea;